import {
  addressesAppDefId,
  ecomAppDefID,
  membersAppDefId,
  myAddressesPageId,
  myWalletAppDefId,
  myWalletPageId,
  orderHistoryPageId,
} from './constants';
import {Experiments} from '../common/experiments/Experiments';
import {createStoreFrontBILogger} from '@wix/wixstores-client-core/dist/src/bi/configure-front-bi-logger';
import {Logger} from '@wix/bi-logger-ec-sf';

let appToken;
let options;
let biLogger: Logger;
let sdk: IEditorSdk;
let experiments: Experiments;

function addOrders() {
  const membersAPI = sdk.application.getPublicAPI(appToken, {appDefinitionId: membersAppDefId});
  return membersAPI.addSection({
    appDefinitionId: ecomAppDefID,
    pageId: orderHistoryPageId,
    social: false,
    showInLoginMenu: true,
  });
}

function addCheckout() {
  return sdk.tpa.isAppSectionInstalled(appToken, {sectionId: 'checkout'}).then(isInstalled => {
    if (!isInstalled) {
      return sdk.tpa.add.component(appToken, {
        appDefinitionId: ecomAppDefID,
        componentType: 'PAGE',
        page: {
          pageId: 'checkout',
          shouldNavigate: false,
        },
      });
    }
  });
}

function installMembers() {
  return sdk.application.install(appToken, {appDefinitionId: membersAppDefId, initiatorAppDefinitionId: ecomAppDefID});
}

function installAddresses() {
  return new Promise((resolve, reject) => {
    if (experiments.enabled('specs.stores.ExpressCheckout')) {
      sdk.tpa
        .isApplicationInstalled(appToken, {appDefinitionId: addressesAppDefId})
        .then(isInstalled => {
          if (!isInstalled) {
            // Temp workaround, till membersAPI will allow to add main section of installed APP.
            const membersAPI = sdk.application.getPublicAPI(appToken, {appDefinitionId: membersAppDefId});
            return membersAPI.addApplication({
              appDefinitionId: addressesAppDefId,
              pageId: myAddressesPageId,
              social: false,
              showInLoginMenu: true,
            });
            // return sdk.tpa.add.application(appToken, {appDefinitionId: addressesAppDefId});
          }
        })
        .then(resolve)
        .catch(reject);
    } else {
      resolve();
    }
  });
}

function installMyWallet() {
  return new Promise((resolve, reject) => {
    if (experiments.enabled('specs.stores.ExpressCheckout') && experiments.enabled('specs.cashier.SaveCreditCard')) {
      sdk.tpa
        .isApplicationInstalled(appToken, {appDefinitionId: myWalletAppDefId})
        .then(isInstalled => {
          if (!isInstalled) {
            // Temp workaround, till membersAPI will allow to add main section of installed APP.
            const membersAPI = sdk.application.getPublicAPI(appToken, {appDefinitionId: membersAppDefId});
            return membersAPI.addApplication({
              appDefinitionId: myWalletAppDefId,
              pageId: myWalletPageId,
              social: false,
              showInLoginMenu: true,
            });
          }
        })
        .then(resolve)
        .catch(reject);
    } else {
      resolve();
    }
  });
}

async function getExperiments(instance: string): Promise<Experiments> {
  const experimentsResponse = await fetch('/_api/wix-ecommerce-storefront-web/api', {
    method: 'post',
    body: JSON.stringify({
      query: require('!raw-loader!../common/experiments/getConfig.graphql'),
      source: 'WixStoresWebClient',
      operationName: 'getConfig',
    }),
    headers: {
      'X-ecom-instance': instance,
      Authorization: instance,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(data => data.json())
    .then(data => {
      return (data.data.experiments || []).reduce((acc, e) => {
        acc[e.name] = e.value;
        return acc;
      }, {});
    });
  return new Experiments(experimentsResponse);
}

async function isMembersInstalled() {
  return sdk.tpa.isAppSectionInstalled(appToken, {sectionId: orderHistoryPageId});
}

export const editorScript = {
  editorReady: async (_editorSDK, _appToken, _options) => {
    options = _options;
    appToken = _appToken;
    sdk = _editorSDK;
    const instance: string = await (sdk as any).document.info.getAppInstance('token');
    const encodedInstance = instance.substring(instance.indexOf('.') + 1);
    const parsedInstance = JSON.parse(atob(encodedInstance));
    const storeId = parsedInstance.instanceId;
    const isMerchant = true;
    biLogger = createStoreFrontBILogger({uuid: parsedInstance.uid}, parsedInstance.biToken, {
      storeId,
      isMerchant,
      appName: 'wixstores worker',
    });

    experiments = await getExperiments(instance);

    return new Promise(async (resolve, reject) => {
      await addCheckout();
      if (options && options.firstInstall) {
        if (options.origin && options.origin.type === 'ADI') {
          return resolve();
        } else {
          return installMembers().then(resolve, reject);
        }
      } else {
        const membersInstalled = await isMembersInstalled();
        if (membersInstalled) {
          //tslint:disable-next-line:no-floating-promises
          biLogger.exposureEventForTests({
            type: 'members_is_installed',
            testName: 'express_checkout',
            is_eligible: true,
          });
        } else {
          //tslint:disable-next-line:no-floating-promises
          biLogger.exposureEventForTests({
            testName: 'express_checkout',
            is_eligible: false,
          });
        }

        if (experiments.enabled('specs.stores.ExpressCheckout')) {
          const addressesInstalled = await sdk.tpa.isApplicationInstalled(appToken, {
            appDefinitionId: addressesAppDefId,
          });
          const myWalletInstalled = await sdk.tpa.isApplicationInstalled(appToken, {appDefinitionId: myWalletAppDefId});
          if ((!addressesInstalled || !myWalletInstalled) && membersInstalled) {
            if (experiments.enabled('specs.cashier.SaveCreditCard')) {
              await installMyWallet();
            }
            await installAddresses();
            await sdk.document.save();
          }
        }
        resolve();
      }
    });
  },
  getAppManifest: () => ({}),
  onEvent: () => {
    /**/
  },
  handleAction: ({type, payload}) => {
    try {
      switch (type) {
        case 'appInstalled':
          switch (payload.appDefinitionId) {
            case membersAppDefId: {
              if (options.firstInstall) {
                //tslint:disable-next-line:no-floating-promises
                biLogger.exposureEventForTests({
                  type: 'members_added_by_stores',
                  testName: 'express_checkout',
                  is_eligible: true,
                });
              } else {
                //tslint:disable-next-line:no-floating-promises
                biLogger.exposureEventForTests({
                  type: 'members_added_by_user',
                  testName: 'express_checkout',
                  is_eligible: true,
                });
              }
              return Promise.all([addOrders(), addCheckout(), installAddresses(), installMyWallet()]);
            }
            default:
              return Promise.resolve();
          }
        default:
          return Promise.resolve();
      }
    } catch (e) {
      return Promise.reject(e);
    }
  },
  getControllerPresets: () => Promise.resolve([]),
};
